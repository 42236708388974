body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.forgot-password-link {
  color: #007bff;  /* Bootstrap primary color */
  text-decoration: none;  /* Removes underline */
  font-weight: 500;  /* Slightly bolder text */
}

.forgot-password-link:hover,
.forgot-password-link:focus {
  color: #0056b3;  /* Darker shade for hover and focus */
  text-decoration: underline;  /* Adds underline on hover/focus */
}

