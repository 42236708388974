/* Navbar.css */

.navbar-custom {
    background-color: #343a40; /* Dark background color */
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
}

.navbar-brand {
    font-size: 1.5rem; /* Larger brand font size */
    font-weight: bold; /* Makes brand name bold */
    color: #f8f9fa !important; /* Brand name color */
}

.nav-link {
    color: #f8f9fa !important; /* Link color */
    transition: color 0.3s; /* Smooth transition for hover effect */
}

.nav-link:hover {
    color: #007bff !important; /* Link hover color */
}

/* More custom styling can be added here */
