.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Features Section */
#features {
	padding: 50px 0;
	background: #f8f8f8;
  }
  
  #features .section-title h2 {
	margin-bottom: 20px;
	color: #333;
	width: 80%; /* Adjust as needed */
	margin-left: auto;
  }
  
  #features .row div {
	margin-bottom: 30px;
  }
  
  #features .row div i {
	margin-bottom: 15px;
	color: #f47820; /* Choose a color that matches your theme */
  }
  
  #features .row h3 {
	margin-bottom: 10px;
	font-size: 18px;
  }
  
  #features .row p {
	font-size: 14px;
	line-height: 1.6;
  }
  
  .about-section {
	padding: 50px 0;
	background-color: #f9f9f9;
  }
  
  .about-img {
	max-width: 100%;
	height: auto;
	border-radius: 5px;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 0 10px #fff, 
              0 0 20px #fff, 
              0 0 30px #fff, 
              0 0 40px #ff00de;
  }
  .about-img:hover {
    transform: scale(1.05);
    box-shadow: 0 0 5px #ff4da6, 
                0 0 15px #ff4da6, 
                0 0 20px #ff4da6, 
                0 0 25px #ff4da6, 
                0 0 30px #ff4da6;
  }
  
  .about-text {
	text-align: left;
	color: #333;
  }
  
  .about-text h2 {
	font-size: 2.5rem;
	margin-bottom: 20px;
  }
  
  .about-text p {
	font-size: 1.1rem;
	line-height: 1.6;
  }
  
  
  .list-style li {
	line-height: 1.6;
  }

  .footer {
    background-color: #333;
    color: white;
    padding: 20px 0;
}

.footer-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-contact,
.footer-social {
    flex: 1;
}

.social-links a {
    margin: 0 10px;
    color: white;
    font-size: 24px;
}

.footer-contact-link {
    display: inline-block;
    margin-top: 10px;
    color: white;
    text-decoration: none;
}

.footer-contact-link:hover {
    text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .footer-flex {
        flex-direction: column;
        align-items: flex-start;
    }

    .footer-contact,
    .footer-social {
        margin-bottom: 20px;
    }
}

.icon-animate {
  transition: transform 0.2s;
}

.icon-animate:active {
  transform: scale(1.5);
}

.separator {
  margin: 3em 0;
  border-top: 1px solid #ddd;
  padding-top: 20px;
}